exports.components = {
  "component---src-pages-blog-archive-js": () => import("./../../../src/pages/blog-archive.js" /* webpackChunkName: "component---src-pages-blog-archive-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-post-js": () => import("./../../../src/pages/blog-post.js" /* webpackChunkName: "component---src-pages-blog-post-js" */),
  "component---src-pages-blog-tag-js": () => import("./../../../src/pages/blog-tag.js" /* webpackChunkName: "component---src-pages-blog-tag-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-content-page-js": () => import("./../../../src/pages/content-page.js" /* webpackChunkName: "component---src-pages-content-page-js" */),
  "component---src-pages-cooperate-js": () => import("./../../../src/pages/cooperate.js" /* webpackChunkName: "component---src-pages-cooperate-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */)
}

